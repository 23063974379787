import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
    text-align: center;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <div className="container">
        <span>
          © {new Date().getFullYear()} Giovanni Setti | Tutti i Diritti
          Riservati
          <br />
          <a href="https://www.iubenda.com/privacy-policy/49874298/full-legal">
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="https://www.iubenda.com/privacy-policy/49874298/cookie-policy">
            Cookie Policy
          </a>
        </span>
      </div>
    </StyledFooter>
  )
}

export default Footer
