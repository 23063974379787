import React, { useContext } from "react"
import { navigate } from "gatsby"
import AppContext from "../context/AppContext"
import StyledForm from "./StyledForm"
import SubmitLayer from "./SubmitLayer"

import axios from "axios"
import { createLinkWithParams, encodeForm } from "../utils/utils"
import { LocationContext } from "../context/LocationContext"

const SecondForm = () => {
  const {
    formData,
    setFormData,
    setAlertState,
    isFormSubmitting,
    setIsFormSubmitting,
  } = useContext(AppContext)

  const { params } = useContext(LocationContext)
  const successLink = createLinkWithParams("/thank-you", params)
  const failureLink = createLinkWithParams("/failure", params)

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData])

  function handleFormChange(e) {
    if (e.target.type === "checkbox")
      return setFormData({ ...formData, [e.target.name]: e.target.checked })
    return setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsFormSubmitting(true)

    const { ready_to_invest, net_amount, saving_amount } = formData

    if (
      !ready_to_invest ||
      ready_to_invest === "false" ||
      (net_amount === "Meno di €30.000" && saving_amount === "Meno di €300")
    ) {
      try {
        formData.qualified = false
        const axiosRequest = axios.create()
        await axiosRequest.post(
          process.env.GATSBY_UNQUALIFIED_ZAPIER_ENDPOINT,
          encodeForm(formData),
          { headers: { Accept: "application/json" } }
        )
        setIsFormSubmitting(false)
        return navigate(failureLink, { state: { formData } })
      } catch (err) {
        console.dir(err)
        setAlertState({
          successful: false,
          message:
            "È avvenuto un errore nell'invio del modulo. Per favore scrivici a info@giovannisetticonsulting.com",
          shown: true,
        })
        setTimeout(() => {
          setAlertState({ successful: false, message: "", shown: false })
        }, 7000)
        setIsFormSubmitting(false)
        return
      }
    } else {
      formData.qualified = true
      await axios.post(
        process.env.GATSBY_QUALIFIED_ZAPIER_ENDPOINT,
        encodeForm(formData),
        { headers: { Accept: "application/json" } }
      )
      setIsFormSubmitting(false)
      return navigate(successLink, { state: { formData } })
    }
  }

  return (
    <StyledForm className="container" id="contatti">
      <h3 className="title">Verifica i tuoi requisiti</h3>
      <form
        action="/contacts"
        className="form"
        method="POST"
        onSubmit={handleSubmit}
      >
        <div className="input-container full-width">
          <label htmlFor="net_amount" className="label">
            A quanto ammonta il tuo patrimonio finanziario? (Soldi liquidi più
            strumenti finanziari già in essere).
          </label>
          <select
            type="text"
            className="text-input"
            id="net_amount"
            name="net_amount"
            required
            value={formData.net_amount}
            onChange={handleFormChange}
          >
            <option value="Meno di €30.000">Meno di €30.000</option>
            <option value="Tra €30.000 e €50.000">Tra €30.000 e €50.000</option>
            <option value="Tra €50.000 e €100.000">
              Tra €50.000 e €100.000
            </option>
            <option value="Più di €100.000">Più di €100.000</option>
          </select>
        </div>
        <div className="input-container full-width">
          <label htmlFor="net_amount" className="label">
            Quanto riesci a risparmiare al mese?
          </label>
          <select
            className="text-input"
            id="saving_amount"
            name="saving_amount"
            required
            value={formData.saving_amount}
            onChange={handleFormChange}
          >
            <option value="Meno di €300">Meno di €300</option>
            <option value="Tra €300 e €500">Tra €300 e €500</option>
            <option value="Tra €500 e €1000">Tra €500 e €1.000</option>
            <option value="Più di €1.000">Più di €1.000</option>
          </select>
        </div>
        <div className="input-container full-width">
          <label htmlFor="ready_to_invest" className="label">
            Saresti pronto a cominciare subito un percorso di investimento?
          </label>
          <select
            className="text-input"
            id="ready_to_invest"
            name="ready_to_invest"
            required
            value={formData.ready_to_invest}
            onChange={handleFormChange}
          >
            <option value={true}>Sì</option>
            <option value={false}>No</option>
          </select>
        </div>
        <div className="submit-container full-width">
          <button className="submit" type="submit">
            <span className="loader d-none"></span>
            <span className="submit--text">Candidati Ora</span>
          </button>
        </div>
      </form>
      {isFormSubmitting && <SubmitLayer isSubmitting={isFormSubmitting} />}
    </StyledForm>
  )
}

export default SecondForm
