import React from "react"
import styled from "styled-components"

import Layout from "../layout/Layout"
import SecondForm from "../components/SecondForm"

const StyledCandidatura = styled.div``

const Candidatura = () => {
  return (
    <Layout salesLetter>
      <StyledCandidatura>
        <div className="container">
          <SecondForm />
        </div>
      </StyledCandidatura>
    </Layout>
  )
}

export default Candidatura
